:root {
   --transition: all 0.3s ease 0s;
}

a,
button,
input {
   -webkit-transition: var(--transition);
   transition: var(--transition);
   text-decoration: none !important;
}
.radius {
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
   -o-border-radius: 8px;
   border-radius: 8px;
}
.pointer {
   cursor: pointer;
}

/*placeholder*/
input::placeholder,
textarea::placeholder {
   color: #929398;
   opacity: 1;
   /* Firefox */
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
   color: #929398;
   opacity: 1;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
   color: #929398;
   opacity: 1;
}
/*placeholder*/

.textarea {
   height: 120px;
   resize: none;
   padding-top: 10px;
   padding-bottom: 10px;
}

.transition {
   -webkit-transition: all 0.3s ease 0s;
   -moz-transition: all 0.3s ease 0s;
   -o-transition: all 0.3s ease 0s;
   transition: all 0.3s ease 0s;
}
button[disabled=""] {
   background: #929398;
}
.cover {
   object-fit: cover;
}
input {
   height: 47px;
   padding-left: 10px;
   padding-right: 10px;
}

.table > :not(:first-child) {
   border-top: 1px solid #e8e9e9;
}
.table > :not(caption) > * > * {
   padding: 13px 10px;
}
.modal.show .modal-dialog.megaMenuModal {
   -webkit-animation-name: stickySlideDown;
   animation-name: stickySlideDown;
   animation-duration: 0.3s;
   animation-direction: normal;
}

@-webkit-keyframes stickySlideDown {
   0% {
      opacity: 0.7;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
   }
   100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
   }
}

@keyframes stickySlideDown {
   0% {
      opacity: 0.7;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
   }
   100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
   }
}
.modal-open {
   padding-right: 0px !important;
}

.modal.fade .modal-dialog {
   -webkit-transform: translate(0, 0) scale(0.5);
   transform: translate(0, 0) scale(0.5);
   -webkit-transition: all 0.2s ease-out 0s;
   transition: all 0.2s ease-out 0s;
}
.modal.show .modal-dialog {
   -webkit-transform: translate(0, 0) scale(1);
   transform: translate(0, 0) scale(1);
   -webkit-transition: all 0.2s ease-out 0s;
   transition: all 0.2s ease-out 0s;
}
/* added by surbhi */

.bRadiusNone {
   border-radius: 0%;
}

.inputBorder {
   border: 1px solid var(--colorLightGrey);
}
/* added by surbhi */
