:root {
   --colorBlack: #0e0e0e;
   --colorWhite: #fff;
   --colorGrey: #666666;
   --colorRed: #ff5101;
   --colorThemeWitmates: #ff5101;
}

@font-face {
   font-family: fThin;
   src: url("../Fonts/Syne-Medium-1.ttf");
}

@font-face {
   font-family: fRegular;
   src: url("../Fonts/Syne-Regular-1.ttf");
}

@font-face {
   font-family: fBold;
   src: url("../Fonts/Syne-SemiBold-1.ttf");
}
body,
li,
a,
header,
footer {
   font-family: fRegular;
}

.fThin {
   font-family: fThin;
}

.fRegular {
   font-family: fRegular;
}

.fBold {
   font-family: fBold;
   /* font-weight: 600; */
}
.theme-text-hover:hover {
   color: var(--colorRed) !important;
}
.witmates-theme-color {
   color: var(--colorThemeWitmates) !important;
}
.witmates-theme-bg {
   background: var(--colorThemeWitmates);
}
