/*All Mobile*/
@media only screen and (max-width: 767px) {
   body body .mobDflex {
      display: flex;
   }
   body .mobPt50 {
      padding-top: 50px;
   }

   .mobPt60 {
      padding-top: 60px !important;
   }
   .mobPt20 {
      padding-top: 20px !important;
   }
   .mobPb60 {
      padding-bottom: 60px !important;
   }
   .mobPt70 {
      padding-top: 70px !important;
   }
   body .mobMt50 {
      margin-top: 50px;
   }

   .mobMt60 {
      margin-top: 60px !important;
   }
   .mobColomnReverse {
      flex-direction: column-reverse;
   }
   .mobdflex {
      display: flex;
   }

   .mobHeadingFontSize {
      font-size: 32px;
   }

   .mobFs20 {
      font-size: 20px;
   }
   .mobFs22 {
      font-size: 22px;
   }
   .mobFs14 {
      font-size: 14px;
   }

   .mobGy3 {
      --bs-gutter-y: 3rem !important;
   }
   .noMobPadding {
      padding-left: 0px !important;
      padding-right: 0px !important;
   }

   .mobLineHeight {
      line-height: normal;
   }
   .mobPt0 {
      padding-top: 0px !important;
   }

   .mobPb0 {
      padding-bottom: 0px;
   }
   body .mobPt10 {
      padding-top: 10px;
   }
   body .mobPt30 {
      padding-top: 30px;
   }
   body .mobMr10 {
      margin-right: 10px;
   }
   body .mobMb30 {
      margin-bottom: 30px;
   }
   body .mobMb0 {
      margin-bottom: 0px;
   }
   body .mobMr10 {
      margin-right: 10px;
   }
   body .mobMb10 {
      margin-bottom: 10px;
   }
   body .mobMb15 {
      margin-bottom: 15px;
   }
   .mobMb20 {
      margin-bottom: 20px !important;
   }
   body .mobMb40 {
      margin-bottom: 40px;
   }
   body .mobMb50 {
      margin-bottom: 50px;
   }
   body .mobMb60 {
      margin-bottom: 60px;
   }
   body .mobMb75 {
      margin-bottom: 75px;
   }
   body .mobMt0 {
      margin-top: 0px;
   }
   body .mobMt10 {
      margin-top: 10px;
   }
   .mobMt15 {
      margin-top: 15px;
   }
   body .mobMt20 {
      margin-top: 20px;
   }
   body .mobMt30 {
      margin-top: 30px;
   }
   .mobMt40 {
      margin-top: 40px !important;
   }

   body .mobPt40 {
      padding-top: 40px;
   }
   body .mobMl0 {
      margin-left: 0px;
   }
   body .mobMl10 {
      margin-left: 10px;
   }
   body .mobPb0 {
      padding-bottom: 0px;
   }
   .mobPb20 {
      padding-bottom: 20px !important;
   }
   .mobPb10 {
      padding-bottom: 10px !important;
   }
   .mobPb5 {
      padding-bottom: 5px !important;
   }
   .mobPt5 {
      padding-top: 5px !important;
   }
   body .mobPb40 {
      padding-bottom: 40px;
   }
   body .mobPb50 {
      padding-bottom: 50px;
   }
   body {
      overflow-x: hidden;
   }

   .mobTextCenter {
      text-align: center;
   }

   .dNoneXs {
      display: none !important;
   }

   .mobGap10 {
      gap: 10px;
   }
}

/*All Mobile*/

/*All Small Tablets*/
@media only screen and (max-width: 991px) and (min-width: 768px) {
   body .mobMb0 {
      margin-bottom: 0px;
   }
   body .mobMr10 {
      margin-right: 10px;
   }
   .dNoneMd {
      display: none !important;
   }
   .ml0Tab {
      margin-left: 0px !important;
   }
   .ml10Tab {
      margin-left: 10px !important;
   }
}

/*All Small Tablets*/

/*All large Tablets*/
@media only screen and (max-width: 1024px) and (min-width: 768px) {
   body .tabWidth100 {
      width: 100%;
   }

   .tabHeadingFontSize {
      font-size: 36px !important;
   }
}

/*All large Tablets*/

/*Only large Tablets*/
@media only screen and (max-width: 1024px) and (min-width: 992px) {
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
   .dNoneLg {
      display: none !important;
   }
}

/*Only large Tablets*/

/*All Small Laptops*/
@media only screen and (max-width: 1400px) and (min-width: 1201px) {
}
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 768px) {
   .dNoneNonMob {
      display: none !important;
   }
}
/*All Small Laptops*/
