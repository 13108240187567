/* banner css */
.banner-main {
   position: relative;
   margin-top: -7rem;
}

.banner-content {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   /* margin-top: 4rem; */
   text-align: center;
}
/* Header CSS  */
header {
   padding: 30px 0;
   background-color: transparent;
}
.react {
   background-color: red !important;
}
.header-section {
   position: sticky;
   top: 0;
   z-index: 100;
   transition: all 0.3s ease-in-out;
   box-shadow: 0 0.1rem 1rem 0.21rem rgba(1, 0, 1, 0.1);
}
.header-section .navbar-nav {
   gap: 1.2rem;
}

.menu-links:hover {
   color: var(--colorRed) !important;
}
.sticky-header {
   background-color: var(--colorWhite);
}
.logo {
   width: 200px;
}

header ul {
   display: flex;
   justify-content: space-between;
   margin-bottom: 0;
   padding-left: 0;
}

.header-section .inactive-menu {
   color: var(--colorWhite);
}

.header-section .active-menu {
   color: var(--colorOrange) !important;
}
.header-section .sticky-header-menu a {
   color: var(--colorBlack);
}

.header-section .navbar-collapse {
   justify-content: center;
}

.header-section .nav-link:hover {
   color: var(--colorRed);
}
.solid-button-theme {
   display: flex;
   align-items: center;
   background: var(--colorRed);
   border: none;
   font-weight: 400;
   position: relative;
   padding: 10px 20px;
   width: 50%;
   justify-content: center;
   font-size: 24px;
   font-family: "DMSANS-FONT";
}

.solid-button-theme span {
   margin-left: 0.4rem;
}
.btn-theme {
   display: flex;
   align-items: center;
   background-color: transparent;
   border: none;
   text-transform: uppercase;
   font-weight: 400;
   position: relative;
   padding-left: 1.3rem;
   padding-right: 1.3rem;
   z-index: 1;
}
.soild-theme-btn {
   display: flex;
   align-items: center;
   background-color: transparent;
   border: none;
   text-transform: uppercase;
   font-weight: 400;
   height: 50px;
   position: relative;
   padding-left: 1.3rem;
   padding-right: 1.3rem;
   z-index: 1;
}
.btn-theme:hover::before {
   width: 100%;
   background: var(--colorRed);
   border-radius: 0px;
}

.btn-theme::before {
   transition: all 0.6s cubic-bezier(0.28, -0.25, 0.565, 1.55);
   content: "";
   width: 50px;
   height: 50px;
   position: absolute;
   top: -12px;
   left: 0;
   border-radius: 50px;
   background: var(--colorRed);
   z-index: -1;
}
.btn-theme span {
   /* color: var(--colorWhite); */
   mix-blend-mode: lighten;
}
.btn-theme:disabled::before {
   width: 100%;
   background: rgb(155, 154, 154);
   border-radius: 0px;
}
.btn-theme:disabled {
   color: #cdcece;
   padding-left: 45px;
}
/* Header CSS  */

/* Home Banner CSS  */

/* .home {
   position: relative;
   margin-top: -7rem;
}
.home-banner {
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   z-index: 5;
   height: 100vh;
   display: flex;
   align-items: center;
   background-color: #000;
}

.home-banner h1 {
   color: var(--colorWhite);
   font-size: 65px;
   line-height: 82px;
   margin-bottom: 30px;
} */

.sub-head {
   height: 125px;
   width: 125px;
   border: 2px solid var(--colorWhite);
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: 0.3s ease-in-out;
   /* padding: 40px 20px; */

   background-image: linear-gradient(to top, var(--colorThemeWitmates) 50%, transparent 50%), linear-gradient(to top, var(--colorThemeWitmates) 50%, transparent 50%);

   -webkit-background-clip: text, padding-box;
   color: white;
   background-size: 100% 200%;
   background-position: top;
   transition: background-position 0.5s ease-in-out;
}
.sub-head:hover {
   border: none;
   background-position: bottom;
}
.sub-head span {
   font-size: 12px;
   color: var(--colorWhite);
   text-transform: uppercase;
}

.sub-description {
   max-width: 430px;
   color: var(--colorWhite);
   text-align: left;
}

.red-bar {
   font-size: 16px;
   text-transform: uppercase;
   font-weight: 400;
}

.red-bar::after {
   content: "";
   position: relative;
   background: var(--colorRed);
   height: 3px;
   display: inline-block;
   width: 80px;
   height: 3px;
   top: -3px;
   left: 25px;
}
/* Home Banner CSS  */

/* About CSS  */
.about-section h2 {
   font-size: 60px;
   margin: 15px 0;
}

.about-section p {
   font-size: 16px;
   font-weight: 400;
   color: var(--colorGrey);
}

.negMl23 {
   margin-left: -23px;
}

.counter-section {
   background: url(../src/assets/images/workprocess.webp);
   background-repeat: no-repeat;
   background-size: contain;
}

.count {
   -webkit-text-stroke-width: thin;
   -webkit-text-stroke: 1.64px var(--colorRed);
   -webkit-text-fill-color: var(--colorWhite);
}
.plus {
   -webkit-text-stroke-width: thin;
   -webkit-text-stroke: 1.64px var(--colorRed);
   -webkit-text-fill-color: var(--colorWhite);
}

.count-number:nth-child(even) {
   margin-top: -2.2rem;
}

.count-number:nth-child(odd) {
   margin-top: -1rem;
}

.count-number {
   font-size: 60px;
}

.factors-card {
   min-height: 262px;
   margin-bottom: 2rem;
   padding: 2rem 3rem 1rem 3rem;
}
/* About CSS  */

/* contact css */
.contact-overlay {
   position: absolute;
   top: 0;
   left: 0;
   color: white;
   padding: 2rem;
}
.arrow-btn {
   position: absolute;
   bottom: 60px;
   right: 4rem;
}
/* Services CSS  */

.services h2 {
   font-size: 60px;
   margin: 15px 0;
   color: var(--colorWhite);
}

.carousel-div {
   position: relative;
   cursor: pointer;
}

.carousel-div img {
   position: relative;
   z-index: -1;
}

.carousel-desc {
   position: absolute;
   top: 20px;
   margin: 60px;
}

.services .carousel-button-group {
   position: absolute;
   top: 9rem;
   right: 16rem;
}

.carousel-button-group img {
   height: 70px;
   width: 70px;
}

.more-section {
   width: 287px;
   position: absolute;
   bottom: 90px;
   left: 60px;
}

/* Choose us css  */
.choose-us h2 {
   font-size: 60px;
   margin: 15px 0 60px 0;
}

.choose-us p {
   text-align: right;
}

/* Choose us css  */

/* form css */
.form-floating > label {
   left: 22px;
   color: var(--colorLightGrey);
}
/* form css */

/* testimonial css */
/* .testimonial .carousel-button-group {
   position: absolute;
   top: 6.2rem;
   right: 17rem;
} */
.testimonial .carousel-button-group .pointer {
   position: absolute;
   top: 44%;
}
.testimonial .carousel-button-group .pointer:nth-child(1) {
   left: 15%;
}
.testimonial .carousel-button-group .pointer:nth-child(2) {
   right: 15%;
}
.testimonial .red-bar::after {
   height: 4px;
   left: 0px;
   width: 50px;
}
/* testimonial css */

/* portfolio css */
.portfolio-card div:nth-child(3n + 2) {
   padding: 1rem 2rem;
}
/* portfolio css */
/* Footer CSS  */
.footer input {
   width: 100%;
}

.section-input {
   position: relative;
}

.input-div {
   position: absolute;
   right: 0px;
   background: var(--colorRed);
   display: flex;
   align-items: center;
   justify-content: center;
   height: 47px;
   width: 47px;
   top: 0;
   cursor: pointer;
}

footer ul {
   padding: 0;
}

footer ul li {
   list-style: none;
}

.footer-logo {
   height: 50px;
}

.query-section {
   background-color: #20272b;
   padding: 15px 0;
   margin-bottom: 20px;
}

/* Footer CSS  */

/* industry css */

.industryList h2 {
   font-size: 60px;
}

.industry-card {
   padding: 2rem;
   min-height: 375px;
   margin-bottom: 2rem;
}
.industryList .red-bar::after {
   height: 4px;
   left: 0px;
   width: 50px;
}

/* how it works */
.howitworks h2 {
   font-size: 60px;
}

.workDot:nth-child(1) {
   top: 26rem;
   left: 6rem;
}

.workDot:nth-child(2) {
   top: 19rem;
   left: 27rem;
}
.workDot:nth-child(3) {
   top: 13rem;
   left: 48rem;
}
.workDot:nth-child(4) {
   top: 3rem;
   left: 69rem;
}
.text-error {
   color: red !important;
}

/* careers css */
.careers .list {
   border-bottom: 1px solid var(--colorWhite);
}

.job-description {
   border-radius: 18px;
   height: 100%;
   padding: 30px;
}

.vertical-white-bar {
   width: 1px;
   height: 44px;
}

.color-on-hover,
.weight-on-hover,
.scale-on-hover,
.translate-on-hover {
   transition: 0.4s;
}

.scale-on-hover {
}
.color-on-hover:hover {
   color: var(--colorOrange);
}

.weight-on-hover:hover {
   font-weight: 700;
}

.scale-on-hover:hover {
   transform: scale(1.2);
}
.translate-on-hover {
   transform: translateY(10%);
}

.zoom-img {
   width: fit-content;
   height: auto;
   overflow: hidden;
}

.zoom-img img {
   transition: 0.7s all ease-in-out;
}

.zoom-img:hover img {
   transform: scale(1.1);
}

/* mobile css starts here */
@media only screen and (max-width: 767px) {
   #card-ui {
      min-height: auto !important;
   }
   .home-banner h1 {
      font-size: 32px;
      line-height: 42px;
   }

   .home-banner .red-bar::after {
      left: 1px;
   }
   .mobImage {
      width: 100%;
   }

   .count-number {
      font-size: 28px;
      text-align: center;
      width: 20%;
   }

   .count-number svg {
      height: 12px;
      width: 14px;
   }

   .count-number h6 {
      font-size: 0.9rem;
   }

   .mobSubTitle {
      font-size: 32px !important;
   }

   .carousel-button-group {
      display: none;
   }

   .services-carousel {
      margin-top: 50px;
   }

   .testimonial svg {
      display: none;
   }

   .testimonialImg {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 50%;
   }

   .mobTestimonial {
      text-align: center;
   }
   .mobTestimonial section {
      margin-bottom: 2rem;
   }

   .choose-us p {
      text-align: left;
   }

   .arrow-btn {
      right: 0rem;
   }

   .awardsImg {
      height: 80px;
   }

   .portfolio-img {
      height: 550px;
      width: 100%;
   }

   .solid-button-theme {
      font-size: 18px;
   }
   .careers .card-header {
      padding: 10px 0px;
   }
   .careers .accordion {
      margin-top: 2rem;
   }
   .carousel-desc {
      margin: 20px;
   }
   .more-section {
      left: 28px;
   }
}

/*All large Tablets*/
@media only screen and (max-width: 1024px) and (min-width: 768px) {
   .awardsImg {
      height: 80px;
   }
}
/* large devices */
@media only screen and (min-width: 1200px) {
   .header-section {
      padding: 1.5rem 0rem;
   }
   .choose-us-features:nth-child(even) {
      padding: 1rem 2rem;
   }
   .custom-dot-list-style {
      display: none !important;
   }
   .choose-us p {
      text-align: right;
   }
   .services-carousel {
      margin-top: 70px;
   }
   .mobTestimonial .reviews-section {
      text-align: center;
      width: 65%;
      display: block;
      margin: 0 auto;
   }
}
