/*------------------------DMSans font----------------------------- */
@font-face {
   font-family: DMSans-font;
   src: url("./assets/Fonts/DMSans/DMSans-variable-font.ttf");
}

/*------------------------Syne font ----------------------------- */

@font-face {
   font-family: Syne-font;
   src: url("./assets/Fonts/Syne-SemiBold-1.ttf");
   /*  src: url("./assets/Fonts/Syne-SemiBold-1.ttf") */
   /* src: url("./assets/Fonts/Syne/Syne-VariableFont_wght.ttf"); */
}

.font-syne {
   font-family: Syne-font;
}

.font-DMSans {
   font-family: DMSans-font !important;
}

.font-weight-100 {
   font-weight: 100;
}

.font-weight-200 {
   font-weight: 200;
}

.font-weight-300 {
   font-weight: 300;
}

.font-weight-400 {
   font-weight: 400;
}

.font-weight-500 {
   font-weight: 500;
}

.font-weight-600 {
   font-weight: 600;
}

.font-weight-700 {
   font-weight: 700;
}

.font-weight-800 {
   font-weight: 700;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
   padding-top: 1.625rem;
   padding-bottom: 0.625rem;
   padding-left: 35px;
}

.react-multiple-carousel__arrow {
   z-index: 3 !important;
}

[data-scroll-container] > div {
   height: auto !important;
}

.backdropClassName {
   background: #ffffff61;
}

@media only screen and (max-width: 767px) {
   .row {
      margin-left: 0;
      margin-right: 0;
   }

   .container {
      .row {
         margin-left: -10px !important;
         margin-right: -10px !important;
      }
   }
}

.fadeIn {
   -webkit-animation-name: fadeIn;
   animation-name: fadeIn;
}

.animation {
   -webkit-animation-duration: 1s;
   animation-duration: 1s;
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

.height-para {
   min-height: 200px;
}

.form-message {
   height: 160px !important;
}

.stickysize {
   width: 160px !important;
}

.bgg9 {
   width: 100%;
   position: relative;
}
.background-css {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
}
.bgg9:before {
   position: absolute;
   content: "";
   background-image: url(./assets/images/leaves-right.png);
   background-repeat: no-repeat;
   background-size: cover;
   width: 280px;
   height: 607px;
   top: 150px;
   left: 0px;
}

.bgg9::after {
   position: absolute;
   content: "";
   background-image: url(./assets/images/leaves-left.png);
   background-repeat: no-repeat;
   background-size: cover;
   width: 280px;
   height: 691.04px;
   top: 150px;
   right: 0px;
}

.g9mtop-135 {
   margin-top: 135px !important;
}

.w-90 {
   width: 90%;
}
.w-80 {
   width: 80% !important;
}

.rightarrow {
   width: 32px;
   height: 32px;
}

.rightleft-arrow:hover {
   background-color: #ff5101;
   border-radius: 35px;
}

.mheight {
   min-height: 750px;
}

.pbot {
   padding-top: 3rem !important;
   padding-bottom: 3rem !important;
}

.verticleline {
   background-size: 280px 200px !important;
   background-image: repeating-linear-gradient(to right, #000000, #1a1a1a 2px, transparent 2px, transparent) !important;
   opacity: 1 !important;
}

.stroke-w-h {
   width: 1249px;
}

.workStroke {
   bottom: 1px;
   position: absolute;
}

.mainstroke {
   z-index: 9;
   position: relative;
   height: 264px;
   width: 100%;
}

.dropdowns-container {
   top: 95px !important;
}

.pb-60 {
   padding-bottom: 60px !important;
}

.pt-60 {
   padding-top: 60px !important;
}

.pb-80 {
   padding-bottom: 80px !important;
}

.pt-80 {
   padding-top: 80px !important;
}
.card-height {
   min-height: 290px !important;
}
.app-img {
   width: 460px !important;
}
.isZoom {
   zoom: unset;
}
.footer-mp {
   margin-bottom: 50px;
   padding-top: 56px;
}
@media (min-width: 1518px) and (max-width: 1821px) {
   .isZoom {
      zoom: 0.8 !important;
   }

   .pt-xxl-60 {
      padding-top: 60px !important;
   }

   .pb-xxl-60 {
      padding-bottom: 60px !important;
   }

   .py-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
   }

   .services-carousel {
      margin-top: 36px !important;
   }

   .mb-35 {
      margin-bottom: 35px !important;
   }

   .mb-40 {
      margin-bottom: 40px !important;
   }

   .mt-0 {
      margin-top: 0px;
   }

   .carousel-desc {
      margin: 50px !important;
      /* margin-top: 30px !important; */
      display: flex;
      justify-content: center;
      flex-direction: column;
   }

   .font-26 {
      font-size: 24px !important;
   }

   .font-15 {
      font-size: 15px !important;
   }

   .height-para {
      min-height: 162px;
   }

   .choose-us h2 {
      margin: 15px 0 25px 0 !important;
      font-size: 50px !important;
   }

   .chooseimg {
      height: 280px !important;
      object-fit: cover;
   }

   .font12 {
      font-size: 12px !important;
      line-height: 20px !important;
   }

   .pt2 {
      padding-top: 2rem !important;
   }

   .mt-40 {
      margin-top: 40px !important;
   }

   .pb-0 {
      padding-bottom: 0px !important;
   }

   .mb-80 {
      margin-bottom: 80px !important;
   }

   .stickysize {
      width: 145px !important;
   }

   .bgg9:before {
      width: 200px;
      height: 434.19px;
      top: 150px;
      left: 0px;
   }

   .bgg9::after {
      width: 200px;
      height: 493.6px;
      top: 150px;
      right: 0px;
   }

   .font54 {
      font-size: 54px !important;
   }
   .font42 {
      font-size: 42px !important;
      line-height: 50px !important;
   }

   .font50 {
      font-size: 50px !important;
      line-height: 58px !important;
   }

   .font26 {
      font-size: 26px !important;
      line-height: 30px !important;
   }

   .font14 {
      font-size: 14px !important;
      line-height: 18px !important;
   }

   .font16 {
      font-size: 16px !important;
      line-height: 24px !important;
   }

   .font18 {
      font-size: 18px !important;
   }
   .font22 {
      font-size: 22px !important;
   }
   .font60 {
      font-size: 60px !important;
   }

   .services h2 {
      font-size: 50px !important;
      line-height: 55px;
   }

   .carousel-button-group img {
      height: 40px !important;
      width: 40px !important;
   }

   .rightarrow {
      width: 25px;
      height: 25px;
   }

   .fs60 {
      font-size: 50px !important;
   }

   .about-section h2 {
      font-size: 50px !important;
   }

   .form-floating > .form-control {
      height: calc(3rem + calc(var(--bs-border-width) * 2)) !important;
      min-height: calc(3rem + calc(var(--bs-border-width) * 2)) !important;
   }

   .form-floating > label {
      padding: 0.7rem 0.75rem !important;
   }

   .form-select {
      line-height: 1.2 !important;
   }

   .form-floating > .form-message {
      height: 115px !important;
   }

   .font40 {
      font-size: 40px !important;
   }

   .pt25 {
      padding-top: 25px !important;
   }

   .mheight {
      min-height: 580px !important;
   }

   .arrow-btn {
      bottom: 14px !important;
      right: 2rem !important;
   }

   .pbot {
      padding-bottom: 0px;
   }

   .stroke-w-h {
      width: 1149px;
   }

   .w-xxl-90 {
      width: 90%;
   }

   .workDot:nth-child(1) {
      top: 23rem !important;
      left: 3rem !important;
   }

   .workDot:nth-child(2) {
      top: 19rem !important;
      left: 21rem !important;
   }

   .workDot:nth-child(3) {
      top: 15rem !important;
      left: 41rem !important;
   }

   .workDot:nth-child(4) {
      top: 2.5rem !important;
      left: 58rem !important;
   }
   .card-height {
      min-height: 234px !important;
   }
   .howitworks h2 {
      font-size: 50px !important;
   }
   .pbot {
      padding-top: 3rem !important;
      padding-bottom: 0rem !important;
   }
   .webimg {
      /* width: 650px !important;
   height: 650px !important;
   object-fit: cover !important; */
   }
}

.modal-content {
   background-color: transparent !important;
   border: none !important;
}
.section-gap {
   padding-top: 80px;
   padding-bottom: 80px;
}
.transition-smooth {
   transition: all !important;
   transition-duration: 500ms !important;
}

@media screen and (max-width: 767px) {
   .section-gap {
      padding-top: 40px;
      padding-bottom: 40px;
   }
   .g9mtop-135 {
      margin-top: 18px !important;
   }
   .smtop-50 {
      margin-top: 60px !important;
   }
   .smtop-46 {
      margin-top: 46px !important;
   }
   .verticleline .services-carousel .custom-dot-list-style {
      margin-bottom: 20px !important;
   }
   .react-multi-carousel-dot button {
      border-color: white !important;
   }
   .react-multi-carousel-dot--active button {
      background-color: #ff5101 !important;
   }
   .footer-mp {
      padding-top: 0px;
   }
   .mtop30 {
      margin-top: 30px !important;
   }
   .mobMt46 {
      margin-top: 46px;
   }
   .py-50 {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
   }
   .mheight {
      min-height: 665px;
   }
   .arrow-btn {
      bottom: 2px !important;
   }
}
