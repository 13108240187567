:root {
   --colorBlack: #111111;
   --colorWhite: #fff;
   --colorGrey: #f7f8f9;
   --colorLightOrange: #f9f7f7;
   --colorOrange: #ec7523;
   --colorRed: #ff5101;
   --colorDarkOrange: #ff5101;
   --colorYellow: yellow;
   --colorLightGrey: #7d7d7d;
   --colorMediumGrey: #666;
   --colorGrey1: #d9d9d9;
   --borderColor: #3d3e42;
}

body .colorBlack {
   color: var(--colorBlack);
}
.colorLightGrey {
   color: var(--colorLightGrey);
}
.colorWhite {
   color: #fff;
}
.bgBlack {
   background: var(--colorBlack);
}


.colorBlack {
   color: var(--colorBlack);
}

.colorOrange {
   color: var(--colorOrange);
}
.colorDarkOrange {
   color: var(--colorDarkOrange);
}
.colorRed {
   color: var(--colorRed);
}
.colorMediumGrey {
   color: var(--colorMediumGrey);
}
.colorGrey {
   color: #7d7d7d;
}
